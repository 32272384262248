import React from 'react'
import './style.scss'
import pagesIcon from './assets/pages.svg';
import chartIcon from './assets/chart.svg';
import clockIcon from './assets/clock.svg';
import bowlingIcon from './assets/bowling.svg';
import arrowIcon from './assets/arrow.svg';
import { LanguageContext } from '../../../../contexts/language.context';
export default function SmartContracts() {
    const {texts} = React.useContext(LanguageContext);
    return (
        <div className="smart-contracts">
            <h1 className='title'>
                {texts.smartContracts.title}
            </h1>
            <div className="content">
                <div className="card">
                    <img src={pagesIcon} className="icon" />
                    <div className="card-content">
                        <div className="card-title">{texts.smartContracts.box1.title}</div>
                        <div className="card-text">
                            {texts.smartContracts.box1.text}
                        </div>
                    </div>
                </div>
                <div className="card">
                    <img src={clockIcon} className="icon" />
                    <div className="card-content">
                        <div className="card-title">{texts.smartContracts.box2.title}</div>
                        <div className="card-text">
                        {texts.smartContracts.box2.text}
                        </div>
                    </div>
                </div>
                <div className="card">
                    <img src={bowlingIcon} className="icon" />
                    <div className="card-content">
                        <div className="card-title">{texts.smartContracts.box3.title} </div>
                        <div className="card-text">
                        {texts.smartContracts.box3.text}
                        </div>
                    </div>
                </div>
                <div className="card">
                    <img src={chartIcon} className="icon" />
                    <div className="card-content">
                        <div className="card-title">{texts.smartContracts.box4.title}</div>
                        <div className="card-text">
                        {texts.smartContracts.box4.text}
                        </div>
                    </div>
                </div>
                <img src={arrowIcon} className="arrow-icon arrow-1" />
                <img src={arrowIcon} className="arrow-icon arrow-2" />
                <img src={arrowIcon} className="arrow-icon arrow-3" />
            </div>
        </div>
    )
}
