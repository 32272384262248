import React from 'react'
import './style.scss';
import { LanguageContext } from '../../../../contexts/language.context';
import Point from './components/Point.js'
export default function About() {
  let {texts} = React.useContext(LanguageContext);
  return (
    <div className="t-process" >
      <h1 className="title">
        {texts.process.title}
      </h1>

      <div className="process-way">
        <div className="content">
          {
            texts.process.list.map((item, index) => <Point key={item.title} title={item.title} text={item.text} />)
          }
        </div>

      </div>
    </div>
  )
}
