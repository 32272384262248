import React from 'react'
import './style.scss'
import Logo from './../../../../assets/images/logo.svg'
import SocialMediaIcons from '../SocialMediaIcons'
export default function Footer() {
  return (
    <div className="footer">
      <div className="content">
        <div className="logo">
          <img src={Logo} alt="oxsconnect logo" className="image" />
        </div>
      <SocialMediaIcons/>
      </div>
    </div>
  )
}
