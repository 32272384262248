import React from 'react'
import './style.scss'

import { LanguageContext } from '../../../../contexts/language.context'
export default function ServiceHero({
  imageUrl,
  title,
  text,
  reverse = false
}) {
  let langContext = React.useContext(LanguageContext);

  const content = <><div className="service-hero__main">
    <h1 className='service-hero__title--main'>
      {title}
    </h1>
    <p className='service-hero__text'>
      {text}
    </p>
  </div>
    <div className="service-hero__image-container">
      <img src={imageUrl} className="service-hero__image" />
    </div></>;
  const contentReverse = <>
    <div className="service-hero__image-container">
      <img src={imageUrl} className="service-hero__image" />
    </div>
    <div className="service-hero__main">
      <h1 className='service-hero__title--main'>
        {title}
      </h1>
      <p className='service-hero__text'>
        {text}
      </p>
    </div>
  </>

  return (
    <div className='service-hero'>
      <div className="service-hero__content">
        {
          reverse ? contentReverse : content
        }
      </div>
    </div>
  )
}
