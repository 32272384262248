import React from 'react'
import './style.scss'
import HeroImage from '../../components/Hero/assets/hero.svg'
import { LanguageContext } from '../../../../contexts/language.context'
export default function Hero() {
  let langContext = React.useContext(LanguageContext);

  return (
    <div className='hero'>
      <div className="hero__content">
        <div className="hero__main">
          <h1 className="hero__title--upper">
            {langContext.texts.hero.upperTitle}
            <span className='hero__title--upper-badge' > OxsBuild </span>
          </h1>
        
          <h1 className='hero__title--main'>
            {langContext.texts.hero.mainTitle}
          </h1>
          <p className='hero__text'>
            {langContext.texts.hero.text}
          </p>
        </div>
        <div className="hero__image-container">
          <img src={HeroImage} className="hero__image" />
        </div>
      </div>
    </div>
  )
}
