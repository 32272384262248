import classNames from 'classnames';
import React from 'react'
import { LanguageContext } from '../../../../contexts/language.context';

import { sendContactForm } from '../../../../services/common.service'
import ReCaptcha from './ReCaptcha';

import './style.scss'


export default function Contact() {
  const { texts } = React.useContext(LanguageContext);
  const isEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const [robotCheck, setRobotCheck] = React.useState("");
  const isNotRobot = (value) => {
    console.log("isNotRobot", value);
    return value;
  }
  const [contactData, setContactData] = React.useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    domain: 'oxsbuild.com'
  })

  const [status, setStatus] = React.useState("");

  const showStatus = (status) => {
    setStatus(status);
    setTimeout(() => {
      setStatus("");
    }, 2000);
  }

  const handleChange = (e) => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value
    })
  }
  const cleanForm = () => {
    setContactData({
      name: '',
      email: '',
      phone: '',
      message: '',
      domain: 'coinoxs.com'
    })
  }

  const checkForm = () => {
    if (contactData.name && isEmail(contactData.email) && isNotRobot(robotCheck)) {
      return true;
    }
    return false;
  }

  const handleClick = () => {
    if (checkForm()) {
      sendContactForm(contactData).then(res => {
        showStatus("success")
        cleanForm();
      }).catch((err) => {
        showStatus("error")
        cleanForm();
      });
    } else {
      showStatus("error")
    }
  }

  return (
    <div className={classNames("contact-form relative", {
      'contact-form--error': status === 'error'
    })} id='contact-us'>
      <div className={classNames('contact-form__alert contact-form__alert--error', {
        'contact-form__alert--success': status === 'success',
      })}>
      </div>

      <h2 className="contact-form__title">
        {texts.contact.title}
      </h2>
      <h4 className="contact-form__text">
        {texts.contact.text}
      </h4>
      <div className="contact-form__form">
        <input onChange={handleChange} onFocus={() => { }} value={contactData.name} name="name" className='contact-form__input' type="text" placeholder={texts.contact.formText1} />
        <input onChange={handleChange} value={contactData.email} name="email" className='contact-form__input' type="email" placeholder={texts.contact.formText2} />
        <textarea onChange={handleChange} value={contactData.message} name="message" className='contact-form__input contact-form__input--text-area' maxLength={240} placeholder={texts.contact.formText4} />
        <ReCaptcha onChange={(value) => setRobotCheck(value)} />
        <button onClick={handleClick} className="contact-form__button" >
          {texts.contact.buttonText}
        </button>
      </div>
    </div>
  )
}
