import { axios, MAIL_API_HOST } from './service'

export const sendContactForm = (data) => {
  return fetch(
    MAIL_API_HOST,
    {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
      },
      body: JSON.stringify(data),
    }
  )
};