import React from 'react'
import RushAwayImage from './assets/rushaway.png'
import PanoyabakImage from './assets/panoyabak.png'
import AvionexImage from './assets/avionex.png'
import FtdImage from './assets/ftd.png'
import GaragecoinImage from './assets/garagecoin.png'
import './style.scss'
import { LanguageContext } from '../../../../contexts/language.context';
import { useContext } from 'react'
export default function Clients() {
    const { texts: {
        clients: { title }
    } } = useContext(LanguageContext)
    return (
        <div className='clients'>
            <div className="clients-title">
                <h1>{title}</h1>
            </div>
            <div className="clients-content">
                <div className="clients-item">
                    <img src={RushAwayImage} />
                </div>
                <div className="clients-item">
                    <img src={PanoyabakImage} />
                </div>
                <div className="clients-item">
                    <img src={AvionexImage} />
                </div>
                <div className="clients-item">
                    <img src={FtdImage} />
                </div>
                <div className="clients-item">
                    <img src={GaragecoinImage} />
                </div>
            </div>
        </div>
    )
}
