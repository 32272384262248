import classNames from 'classnames';
import React from 'react'
import { LanguageContext } from '../../../../contexts/language.context';
import './style.scss'
//faq__item--open
export default function FAQ() {
    const [selected, setSelected] = React.useState(0);
    const { texts } = React.useContext(LanguageContext);


    return (
        <div className="faq">
            <div className="faq__container">
                <div className="faq__title">
                    {texts.FAQ.title}
                </div>
                <div className='faq__list'>
                    {texts.FAQ.list.map((item, i) => <div key={item.title} onClick={() => {
                        console.log(i);
                        if (i == selected) setSelected(null);
                        else setSelected(i);
                    }} className={classNames('faq__item', { 'faq__item--open': i == selected })}>
                        <div className='faq__subtitle'>
                            <p> {item.title} </p>
                            <img src={require('./assets/arrow.svg').default} />
                        </div>
                        <div className='faq__text' dangerouslySetInnerHTML={{__html:item.text}}>
                        </div>
                    </div>)}
                    
                </div>
            </div>
        </div>
    )
}
