import { createContext } from "react";
import languageData from "./language.data";
import React from "react";

export const LanguageContext = createContext({});

export const LanguageProvider = ({ children }) => {
    const [selectedLanguage, setSelectedLanguage] = React.useState(localStorage.getItem("language") || window.navigator.language.split("-")[0] || "en");
    const [texts, setTexts] = React.useState({
        header: {
            nav1: "",
            nav2: "",
            nav3: "",
            nav4: "",
            nav5: "",
            nav6: "",
        },
        hero: {
            upperTitle: "",
            mainTitle: '',
            text: '',
        },
        banner: {
            upperTitle: '',
            mainTitle: '',
            text: '',
            button1: '',
            button2: ''
        },

        process: {
            title: 'Tokenization Process',
            list: [
                {
                    title: 'Business and asset development',
                    text: `One of the most important stages in tokenization. The main purpose of value transfer and business development is to determine how the token will be valued.`
                },
            ]
        },
        possibilities: {
            title: "",
            list: []
        },
        scrollableNews: {
            title: "",
            text: "",
            list: [
                {
                    title: '',
                    text: ''
                }
            ]
        },
        smartContracts: {
            title: '',
            box1: {
                title: '',
                text: ''
            },
            box2: {
                title: '',
                text: ''
            },
            box3: {
                title: '',
                text: ''
            },
            box4: {
                title: '',
                text: ''
            }
        },
        nftProjects: [],
        FAQ: {
            title: "",
            list: []
        },
        contact: {
            title: "",
            text: "",
            formText1: "",
            formText2: "",
            buttonText: ""
        },
        socialMediaLinks: {
            twitter: ''
        },
        clients:{
            title:''
        },
        serviceHeros:[
            {
                title: '',
                text: ''
            },
            {
                title: '',
                text: ''
            },
            {
                title: '',
                text: ''
            }
        ]
    });
    return <LanguageContext.Provider value={{
        selectedLanguage: selectedLanguage,
        languages: {
            ...languageData
        },
        texts: texts,
        loadLang() {
            setTexts(this.languages[this.selectedLanguage]);
            console.log("new Lang loaded");
        },
        set(val) {
            setSelectedLanguage(val);
            console.log("Language changed to " + val);
            localStorage.setItem("language", val);
        }
    }}>{children}</LanguageContext.Provider>;
}