import React from 'react'
import './style.scss'
import Logo from '../../../../assets/images/logo.svg'

import { LanguageContext } from '../../../../contexts/language.context'
import classNames from 'classnames';
import SocialMediaIcons from '../SocialMediaIcons';

export default function Header() {
  let langContext = React.useContext(LanguageContext);
  let [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  React.useEffect(() => {
    console.log(langContext.loadLang());
    console.log(langContext.texts);
  }, [langContext.selectedLanguage]);

  const scrollTo = (id) => {
    const element = document.querySelector(id)
    const headerOffset = 50;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    console.log(offsetPosition);
    window.scrollTo({
      behavior: 'smooth',
      top: offsetPosition
    });
  }

  return (
    <div className='header'>
      <div className="header__content">
        <div className="header__logo">
          <img src={Logo} alt="Keep logo" />
        </div>
        <div className={classNames("header__nav", {
          open: isOpen
        })}>
          <ul className='header__nav-list'>
            <li className="header__nav-item" onClick={() => { scrollTo('#about'); toggleMenu() }}>
              {langContext.texts.header.nav1}
            </li>
            <li className="header__nav-item" onClick={() => { scrollTo('#tokenization'); toggleMenu() }}>
              {langContext.texts.header.nav2}
            </li>
            <li className="header__nav-item" onClick={() => { scrollTo('#smart-contracts'); toggleMenu() }}>
              {langContext.texts.header.nav3}
            </li>
            <li className="header__nav-item" onClick={() => { scrollTo('#nft'); toggleMenu() }}>
              {langContext.texts.header.nav4}
            </li>
            <li className="header__nav-item" onClick={() => { scrollTo('#faq'); toggleMenu() }}>
              {langContext.texts.header.nav5}
            </li>
            <li className="header__nav-item" onClick={() => { scrollTo('#clients'); toggleMenu() }}>
              {langContext.texts.header.nav6}
            </li>
          </ul>
        </div>
        <SocialMediaIcons hiddenSm />
        <div className="header__action">
          <select onChange={(e) => {
            langContext.set(e.target.value)
          }} value={langContext.selectedLanguage} className="header__language_select">
            <option value="en">EN</option>
            <option value="tr">TR</option>
          </select>
        </div>
        <div>
          <div onClick={() => toggleMenu()} className="header__hamburger-button" />
        </div>
      </div>
    </div>
  )
}
