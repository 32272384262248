export default {
    en: {
        header: {
            nav1: 'About Us',
            nav2: 'Tokenization',
            nav3: 'Smart Contracts',
            nav4: 'NFT',
            nav5: 'FAQ',
            nav6: 'Clients',
        },
        hero: {
            upperTitle: 'Blockchain kitchen',
            mainTitle: 'of Coinoxs Ecosystem',
            text: `Meet tomorrows Technologies today. We are here to complete your Blockchain integration.`
        },
        banner: {
            upperTitle: 'FIRST EVENT PLACE',
            mainTitle: 'OxsBuild',
            text: `Listens to your needs, determines and projects the right decentralized solution for your business. It adds value to your company or idea with its blockchain-based business development strategies.<br/><br/> Tokenization, creation of NFT collections, smart contract development, tokenomic, decentralized applications, blockchain consulting; all and more at OxsBuild, the blockchain kitchen of the Coinoxs ecosystem.`,
            button1: 'Learn More',
            button2: 'Jump In'
        },
        process: {
            title: 'Tokenization Process',
            list: [
                {
                    title: 'Business and asset development',
                    text: `One of the most important stages in tokenization. The main purpose of value transfer and business development is to determine how the token will be valued.`
                },
                {
                    title: 'Tokenomics and value circulation',
                    text: 'The most important marketing tool to help investors understand the purpose, functionality, use cases and distribution of the token. In addition, the project team, past projects, developer activity, quality and quantity of the community, and technical analysis also support value creation.'
                },
                {
                    title: 'Technical documentation and research development',
                    text: 'It includes researching and clarifying the project and project mechanics. Which programming language will be used? What will our platform be? What are our security stages?'
                },
                {
                    title: 'Dex listing',
                    text: 'After token development is complete, it is listed on targeted exchanges. Necessary technological support for listing can be provided by us.'
                },
                {
                    title: 'Coinoxs IDO LAUNCH PAD TOKEN PRESALE',
                    text: 'Presales start with listing the token on the Coinoxs Launch Pad. Token sales made are used as investments for product development costs.'
                },
                {
                    title: 'Whitepaper',
                    text: 'The document that publishes the purpose, scope, innovations and technical details of the Token. This document, which contains information such as the point of departure, the consensus mechanism used in the verification of transfer transactions, the production and distribution of cryptocurrencies, is written in a way that can be understood by both investors and technology teams who will be interested in the project, as well as cryptocurrency miners who verify transactions, and users.'
                },
                {
                    title: 'Maintenance and support services',
                    text: 'Smart contracts development and ongoing technical support is available for a monthly fee.'
                },
                {
                    title: 'MVP and Product Development, Landing Page',
                    text: 'The usable promotional model of our product is called MVP. The pre-sale we mentioned in the previous step is made with the MVP of the token, while the Token development process continues.'
                },
                {
                    title: 'End-to-end project delivery within the agreed scope',
                    text: ''
                }

            ]
        },
        possibilities: {
            title: "What will people find here?",
            list: [
                "Projects",
                "Events",
                "Academy",
                "Networking",
                "Events",
                "Partnerships",
                "Experience"
            ]
        },
        scrollableNews: {
            title: "Explore Meet-ups and Events",
            text: "Our offline events and meetups in various cities —Meetups give community members the opportunity to connect and share ideas in person, hear from team members, industry experts and special guests.",
            list: [
                {
                    title: 'Traditional Investments Vs Crypto Assets Investments',
                    text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium'
                }
            ]
        },
        smartContracts: {
            box1: {
                title: 'Pre-Defined contract',
                text: 'Terms agreed with all counterparties.Hard-coded into the smart contract and cannot be changed without all parties knowing.'
            },
            box2: {
                title: 'Events',
                text: 'Event triggers contract execution.'
            },
            box3: {
                title: 'Settlement',
                text: 'Payout and other settlement is completed instantly and efficiently.'
            },
            box4: {
                title: 'Execution & value transfer',
                text: 'The smart contract is automatically executed based on the pre-agreed terms.'
            }
        },
        smartContracts: {
            title: 'Smart Contracts',
            box1: {
                title: 'Pre-Defined contract',
                text: 'Terms agreed with all counterparties.Hard-coded into the smart contract and cannot be changed without all parties knowing.'
            },
            box2: {
                title: 'Events',
                text: 'Event triggers contract execution.'
            },
            box3: {
                title: 'Settlement',
                text: 'Payout and other settlement is completed instantly and efficiently.'
            },
            box4: {
                title: 'Execution & value transfer',
                text: 'The smart contract is automatically executed based on the pre-agreed terms.'
            }
        },
        nftProjects: [
            "Defining asset",
            "Choosing standard",
            "Picking the right blockchain",
            "Deciding where to store the media",
            "Minting strategies",
            "Allowing tokens to be burned",
            "Paying royalties",
            "Setting up the non-fungible token",
            "Creating a smart contract",
            "Connecting a wallet to the project and creating a deployment script"
        ],
        FAQ: {
            title: "Frequently Asked Questions",
            list: [
                {
                    title: "What is OxsBuild?",
                    text: `<p>OxsBuild; within the Coinoxs ecosystem, provides blockchain-based product development and blockchain consulting services. It helps your business and idea to achieve rapid growth with tokenization, smart contract developing and blockchain-based business development strategies.<br/><br/></p>
                    <p>We work for the wider understanding and adoption of blockchain technologies. Contact now to complete your digital transformation with the technology of the future now.</p>`
                },
                {
                    title: "What is blockchain?",
                    text: `<p>Blockchain is a recording technology that allows data to be recorded on multiple anonymous books without the need for any centralized authority. With this technology, data is recorded in blocks and it is not possible to make changes on previously created blocks. Due to its nature, the data stored on the blockchain is anonymous, transparent and unalterable.</p><br/><br/><p>Blockchain technologies can be applied to all sectors and ideas that are conducive to digitization. With its unique encryption mechanism, it enables much more secure, fast and efficient information transfer than traditional methods.</p>`
                },
                {
                    title: "What is tokenization?",
                    text: `<p>Tokenization; It is the process of creating digital assets that represent physical or non-physical assets on the blockchain. In other words, the process of tokenization means digitizing real or virtual assets. Blockchain technology makes it possible to tokenize almost any asset and store it in a transparent and immutable way. The value transferred from the physical world to the virtual world can be exchanged between anonymous parties with high efficiency thanks to the technology of the future.
                    </p><br/><p>OxsBuild manages the entire process for you, from how the token is added value to the DEX listing of the token. It establishes the supply-demand balance of the token and creates the White Paper of the project (the document that explains the purpose, scope, innovations and technical details of the token) with the crystal clear explanation.</p>`
                },
                {
                    title: "What are the phases of tokenization?",
                    text: `<p>Tokenization is a multi-stage process that requires expertise. In order to create a successful digital asset, it is important that all stages are complete and faultless. Our expert team performs all the stages in the token creation process for you.
                    The stages of tokenization are as follows: </p>
                        <br/>
                        •	Business development and asset creation
                       
                    <p> <br/>At this stage, details that form the main lines of the project such as how to add value to the token, the main purpose of the token and how it will be evaluated are determined.</p>
                        <br/>
                        •	Determining the tokenomic and offering program
                        
                    <p><br/>This process is the most specialized stage of tokenization. It involves the careful establishment of all the factors that determine the value of the token.</p>
                        <br/>
                        •	Creation of technical documents and research
                        
                    <p><br/>This is the stage where the mechanics that the token will use are researched and clarified. Technical questions such as which language to use, which blockchain platform the project will be located on, and determination of security stages are answered.</p>
                        <br/>
                        •	Preparation of the White Paper
                       
                    <p> <br/> It is the stage of documenting technical details such as the purpose of the token, its scope, the innovations it will bring, the consensus mechanism it uses, and the tokenomic strategy at a level that all users can understand. This document, called the “White Paper” in the blockchain literature, is the showcase of your project, as it is the first resource for users to recognize and evaluate the token.</p>
                        <br/>
                        •	Coinoxs IDO Launchpad
                        
                    <p><br/> The tokens that are now ready for sale are put up for pre-sale on the Coinoxs Launchpad platform. Tokens that not listed yet are offered to users at favorable prices. Income from sales is used as a source during product development stages.</p>
                        <br/>
                        •	Listing on Decentralized Exchanges (DEX)
                        
                    <p> <br/>After the completion of the product development stages and the pre-sale, it is the stage where the necessary technological infrastructure is provided to list the token on Decentralized exchanges.</p>
                        <br/>
                        •	Maintenance and technical support services
                        
                    <p><br/>After the token takes its place on the exchanges, OxsBuild continues its service in smart contract configuration and technical support. Maintenance and support services are subject to a monthly fee.</p>
                        <br/>
                        •	MVP, Product development and welcome page
                        
                    <p><br/> An MVP (Minimum Viable Product) token is a feature-rich version that is offered to early customers for feedback. MVP and welcome web page designed for your product is designed by OxsBuild.</p>
                    <p> <br/> 
                        • End-to-end project delivery as agreed
                        <br/>
                        <br/>
                            - 
                        ​</p>
                    </p>
                    `
                },
                {
                    title: "What is a Decentralized Exchange (DEX)? What does DEX listing mean?",
                    text: `<p>Assets digitized with blockchain infrastructure meet with its investor on decentralized exchanges (DEX). Basically, DEXs are platforms that host financial instruments that allow any peer-to-peer exchange, decentralized trading. Thanks to its decentralized structure, transactions are anonymous, fast and secure. <br/><br/></p>
                    <p>It is possible to attribute the success of Tokenomics and a token to the decentralized exchanges on which it is listed. OxsBuild manages the process of listing the token on decentralized exchanges after tokenization.</p>
                    `
                },
                {
                    title: "What is tokenomic?",
                    text: `<p>
                    Tokenomics; It is a combination of the words token and economy. All economic policies regarding the value, production and distribution of tokenized goods and services fall within the scope of tokenomic.
                    </p><p> <br/> A token's tokenomic strategies play a key role in the growth of the project. Tokenomic acts as a guide to understand how valuable an asset might be in the future. OxsBuild determines the most accurate tokenomic mechanism for you so that your token can launch successfully. Contact our experienced team now to determine your token's offering schedule and create a pinpoint tokenomic strategy.
                    </p>`
                },
                {
                    title: "What is NFT?",
                    text: `<p>
                    NFT stands for non-fungible token. Thanks to blockchain technology, the uniqueness and immutability of tokenized digital assets is assured. Digital designs, audio files, videos, social media posts, even physical objects can be digitized and enter NFT form.</p>
                    <p> <br/>With OxsBuild expertise, you can reach all the support you need to design your own NFT collection and bring it together with collectors. Contact us now to give the start of your dream NFT Project.</p>`
                },
                {
                    title: "What is a Smart Contract?",
                    text: `<p>
                    Smart Contracts are digital contracts that run on the blockchain. It ensures secure, low-cost and high efficiency transactions and payments without any central authority between the parties. Every conceivable form of contracts can be digitized thanks to blockchain technology. With their wide usage areas and advantages, smart contracts have already started to make radical changes in the business and art world.
                    </p><p> <br/> Thanks to smart contracts and blockchain-based decentralized applications that we will develop in line with your needs, you can put your business in a rapid growth phase. Contact us to start your blockchain transformation today.
                    </p>`
                },
                {
                    title: "What is a decentralized application (DApp)?",
                    text: `<p>
                    The difference between decentralized applications and other software applications is that they run on a blockchain network instead of a central server. As with all blockchain-based products and services, data is anonymous, accessible and transparent to everyone on the network. Data backed up and stored on the blockchain is protected against modification, censorship and malicious attempts.
                    </p><p> <br/> The decentralized applications of the decentralized world can be easily integrated into any industry and application area touched by the traditional internet. Contact our experts now to project your decentralized applications and business development strategies.
                    </p>`
                },
            ]
        },
        contact: {
            title: "What would you like to solve together?",
            text: "Contact our experts now",
            formText1: "Enter Name/Surname",
            formText2: "Enter Email",
            formText4: "Enter Message",

            buttonText: "Contact Us"
        },
        socialMediaLinks: {
            twitter: 'https://twitter.com/coinoxs',
        },
        clients: {
            title: 'Clients'
        },
        serviceHeros: [
            {
                title: 'Blockchain Transformation',
                text: `If you want to complete your company's digital transformation with the power of blockchain technologies or to actualize your dream blockchain project, you are at the right place`
            },
            {
                title: 'Business Development',
                text: `OxsBuild listens to your needs, determines and projects the right decentralized solution for your business. It adds value to your company or idea with its blockchain-based business development strategies.`
            },
            {
                title: 'Blockchain Based Services',
                text: `Tokenization, creation of NFT collections, smart contract development, tokenomic, decentralized applications, blockchain consulting; all and more at OxsBuild, the blockchain kitchen of the Coinoxs ecosystem`
            }
        ]
    },
    tr: {
        header: {
            nav1: 'Hakkımızda',
            nav2: 'Tokenizasyon',
            nav3: 'Akıllı Sözleşmeler',
            nav4: 'NFT',
            nav5: 'SSS',
            nav6: 'Müşterilerimiz',
        },
        hero: {
            upperTitle: 'Coinoxs Ekosisteminin ',
            mainTitle: 'Blockchain mutfağı',
            text: 'Yarının teknolojisiyle bugün tanışın. Blockchain entegrasyonunuzu tamamlamak için buradayız.',
        },
        banner: {
            upperTitle: 'FIRST EVENT PLACE',
            mainTitle: 'OxsConnect Decentralland',
            text: `Yarının teknolojisiyle bugün tanışın.
            Şirketinizin dijital dönüşümünü blockchain teknolojilerinin gücüyle tamamlamak ya da hayalinizdeki blockchain projesini hayata geçirmek istiyorsanız doğru yerdesiniz. 
            OxsBuild, ihtiyaçlarınızı dinler, işiniz için en doğru merkeziyetsiz çözümü belirler ve projelendirir. Oluşturduğu blockchain tabanlı iş geliştirme stratejileri ile şirketinize veya fikrinize değer katar.
            Tokenizasyon, NFT koleksiyonu oluşturma, akıllı sözleşme yazımı, tokenomic, merkeziyetsiz uygulamalar, blockchain danışmanlığı ve daha fazlası; Coinoxs ekosisteminin blockchain mutfağı OxsBuild’de .
            Geleceğin teknolojisiyle henüz tanışmadıysanız, blockchain entegrasyonunuzu tamamlamak için buradayız. Birlikte neyi çözmek istersiniz? Hemen uzmanlarımız ile iletişime geçin.
            `,
            button1: 'Daha fazla',
            button2: 'Ziyaret et'
        },
        process: {
            title: 'Tokenizasyon’un aşamaları',
            list: [
                {
                    title: 'İş geliştirme ve varlık oluşturma',
                    text: `Bu aşamada tokene nasıl değer yükleneceği, tokenin ana amacı ve nasıl değerlendirileceği gibi ana projenin ana hatlarını oluşturan detaylar belirlenir.`
                },
                {
                    title: 'Tokenomic ve arz programının belirlenmesi',
                    text: `Bu süreç tokenizasyonun en uzmanlık gerektiren aşamasıdır. Token’in değerini belirleyen tüm etmenlerin titizlikle kurulanmasını içerir.`
                },
                {
                    title: 'Teknik belgelerin oluşturulması ve araştırma',
                    text: `Tokenin kullanacağı mekaniğin araştırılıp netleştirildiği aşamadır. Hangi dilin kullanılacağı, projenin hangi blockchain platformunda bulunacağı, güvenlik aşamalarının belirlenmesi gibi teknik sorular cevaplandırılır.`
                },
                {
                    title: 'White Paper’ın hazırlanması',
                    text: 'Tokenin amacı, kapsamı, getireceği yenilikler, kullandığı konsensüs mekanizması, tokenomic stratejisi gibi teknik detayların tüm kullanıcıların anlayacağı düzeyde belgelenmesi aşamasıdır. Blockchain literatüründe “White Paper” olarak adlandırılan bu belge, kullanıcıların token’i tanıyıp değerlendireceği birinci kaynak olması sebebiyle projenizin vitrinidir.'
                },
                {
                    title: 'Coinoxs Launchpad ve IDO',
                    text: `Artık satışa hazır hale gelmiş olan token’ler, Coinoxs Launchpad platformu üzerinde ön satışa çıkarılır. Henüz listelenmemiş tokenleri kullanıcılara avantajlı fiyatlar ile sunulur. Yapılan satışlardan elde edilen gelir, ürün geliştirme aşamalarında kaynak olarak kullanılır​`
                },
                {
                    title: 'Merkeziyetsiz borsalarda (DEX) listelenme',
                    text: 'Ürün geliştirme aşamalarının ve ön satışın tamamlanmasının ardından, token’i merkeziyetsiz borsalar listemeleri için gerekli teknolojik altyapının sağlandığı aşamadır.'
                },
                {
                    title: 'Bakım ve teknik destek hizmetleri',
                    text: 'Token borsalarda yerini aldıktan sonra, OxsBuild akıllı kontrat yapılandırma ve teknik destek konularında hizmetini sürdürür. Bakım ve destek hizmetleri, aylık ücrete tabidir. '
                },
                {
                    title: 'MVP, Ürün geliştirme ve karşılama sayfası',
                    text: 'MVP (Minimum Uygulanabilir Ürün) token’in, geri bildirim almak üzere erken müşterilere sunulan, yeterli özelliklere sahip versiyonudur. MVP ve ürününüz için dizayn edilmiş karşılama web sayfası OxsBuild tarafından tasarlanır. '
                },
                {
                    title: 'Kararlaştırılan kapsamda uçtan uca proje teslimi',
                    text: `Tüm aşamaların tamamlanmasının ardından, proje kararlaştırıldığı şekliyle teslim edilir. Token geliştirme süreci sona erer. ​`
                }

            ]
        },
        possibilities: {
            title: "Oxs Connect’te neler var?",
            list: [
                "Projeler",
                "Ürünler",
                "Akademi",
                "Network",
                "Etkinlikler",
                "İş birlikleri",
                "Deneyim"

            ]
        },
        scrollableNews: {
            title: "Etkinlikleri ve Buluşmaları Keşfedin",
            text: "Çeşitli şehirlerdeki çevrimdışı etkinliklerimiz ve buluşmalarımız — Buluşmalar, topluluk üyelerine bağlantılar kurma ve fikirlerini paylaşma, ekip üyelerinden, sektör uzmanlarından ve özel konuklardan haber alma fırsatı verir.",
            list: [
                {
                    title: 'Geleneksel Yatırımlar ve Kripto Varlık Yatırımları',
                    text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium'
                }
            ]
        },
        smartContracts: {
            title: 'Akıllı kontratlar',
            box1: {
                title: 'İhtiyaca yönelik akıllı kontratlar',
                text: 'Karşılıklı fikir birliğiyle düzenlenmiş, kodları ancak hemfikir olunması halinde değişebilen akıllı kontratlar.'
            },
            box2: {
                title: 'Etkinlikler',
                text: 'Sözleşmenin çalışmaya başlamasını tetikleyen etkinlikler.'
            },
            box3: {
                title: 'Anlaşmalar',
                text: 'Anında ve yüksek verimlilikle tamamlanan ödeme anlaşmaları ve diğer anlaşmalar.'
            },
            box4: {
                title: 'Yürütme ve değer aktarımı',
                text: 'Önceden kararlaştırılan şartlara göre otomatik olarak yürütülen akıllı sözleşmeler.'
            }
        },
        nftProjects: [
            "Varlığın tanımlanması",
            "Projenin temellerinin belirlenmesi ",
            "Doğru blokchain ağının seçimi ",
            "Verilerin nerede depolanacağının seçilmesi",
            "Minting stratejisinin belirlenmesi",
            "Token yakımının mümkün hale getirilmesi",
            "Telif ve lisans ücretlerinin ödenmesi ",
            "NFT’nin üretimi",
            "Akıllı sözleşmelerin oluşturulması",
            "Projeye dijital cüzdanların bağlanması ve dağıtım stratejilerinin belgelenmesi"
        ],
        FAQ: {
            title: "Sıkça Sorulan Sorular",
            list: [
                {
                    title: "OxsBuild nedir?",
                    text: `<p>OxsBuild; Coinoxs ekosistemi içerisinde, blockchain tabanlı ürün geliştirme ve blockchain danışmanlık hizmetleri sunar. Tokenizasyon, akıllı kontrat yazımı ve blockchain tabanlı iş geliştirme stratejileri ile işinizin ve fikrinizin hızlı bir büyüme yakalamasına yardımcı olur. <br/><br/></p>
                    <p>Blockchain teknolojilerinin, daha geniş kitleler tarafından anlaşılması ve benimsenmesi için çalışıyoruz. Dijital dönüşümünüzü geleceğin teknolojisiyle, şimdiden tamamlamak için hemen iletişime geçin. </p>`
                },
                {
                    title: "Blockchain nedir?",
                    text: `<p>Blockchain, herhangi bir merkezi otoriteye ihtiyaç duyulmaksızın, verilerin birden fazla anonim defter üzerinde kaydedilmesine olanak sağlayan kayıt teknolojisidir. Blok zincirler olarak Türkçe’ye çevirdiğimiz bu teknolojiyle, veriler bloklar halinde kaydedilir ve önceden oluşturulmuş bloklar üzerinde değişiklik yapılması mümkün olmaz. Yapısı gereği blockchain üzerinde depolanan veriler anonim, şeffaf ve değiştirilemezdir. </p><br/>
                      <br/><p>Blockchain teknolojileri, dijitalleşmeye elverişli tüm sektör ve fikirlere uygulanabilir. Benzersiz şifreleme mekanizmasıyla, geleneksel yöntemlerden çok daha güvenli, hızlı ve verimli bilgi transferini mümkün kılar. </p>`
                },
                {
                    title: "Tokenizasyon nedir?",
                    text: `<p>Tokenizasyon; Blockchain üzerinde, fiziksel ya da fiziksel olmayan varlıkları temsil eden dijital varlıklar oluşturulması işlemidir. Basit bir deyişle token haline getirme veya tokenizasyon işlemi; reel veya sanal varlıkları dijitalize etmek demektir. Blockchain teknolojisi hemen tüm varlıkların tokenize edilebilmesini, şeffaf ve değiştirilemez şekilde depolanmasını mümkün kılar. Fiziksel dünyadan sanal dünyaya aktarılmış olan değer, geleceğin teknolojisi sayesinde anonim taraflar arasında yüksek verimlilik ile takas edilebilir.
                    </p><br/><p>OxsBuild, tokene nasıl değer yükleneceğinden tokenin DEX listelemesine kadarki tüm süreci sizin için yönetir. Tokenin arz talep dengesini kurar ve projenin White Paper’ını (tokenin amacını, kapsamını, yeniliklerini ve teknik ayrıntılarını açıklayan belge) en anlaşılır şekilde oluşturur.</p>`
                },
                {
                    title: "Tokenizasyon’un aşamaları nelerdir?",
                    text: `<p>Tokenizasyon uzmanlık isteyen çok aşamalı bir süreçtir. Başarılı bir dijital varlık ortaya çıkarmak için tüm aşamaların eksiksiz ve hatasız gerçekleşmesi önemlidir. Token oluşturma sürecinde geçilen tüm aşamaları, alanında uzman ekibimiz sizin için gerçekleştirir. 
                    <br/> Tokenizasyonun aşamaları şöyledir: <br/></p>
                        <br/>
                        •	İş geliştirme ve varlık oluşturma
                       
                    <p> <br/>Bu aşamada tokene nasıl değer yükleneceği, tokenin ana amacı ve nasıl değerlendirileceği gibi ana projenin ana hatlarını oluşturan detaylar belirlenir.</p>
                        <br/>
                        •	Tokenomic ve arz programının belirlenmesi
                        
                    <p><br/>Bu süreç tokenizasyonun en uzmanlık gerektiren aşamasıdır. Token’in değerini belirleyen tüm etmenlerin titizlikle kurulanmasını içerir.</p>
                        <br/>
                        •		Teknik belgelerin oluşturulması ve araştırma
                        
                    <p><br/>Tokenin kullanacağı mekaniğin araştırılıp netleştirildiği aşamadır. Hangi dilin kullanılacağı, projenin hangi blockchain platformunda bulunacağı, güvenlik aşamalarının belirlenmesi gibi teknik sorular cevaplandırılır.</p>
                        <br/>
                        •	White Paper’ın hazırlanması
                       
                    <p> <br/> Tokenin amacı, kapsamı, getireceği yenilikler, kullandığı konsensüs mekanizması, tokenomic stratejisi gibi teknik detayların tüm kullanıcıların anlayacağı düzeyde belgelenmesi aşamasıdır. Blockchain literatüründe “White Paper” olarak adlandırılan bu belge, kullanıcıların token’i tanıyıp değerlendireceği birinci kaynak olması sebebiyle projenizin vitrinidir.</p>
                        <br/>
                        •	Coinoxs Launchpad ve IDO
                        
                    <p><br/> Artık satışa hazır hale gelmiş olan token’ler, Coinoxs Launchpad platformu üzerinde ön satışa çıkarılır. Henüz listelenmemiş tokenleri kullanıcılara avantajlı fiyatlar ile sunulur. Yapılan satışlardan elde edilen gelir, ürün geliştirme aşamalarında kaynak olarak kullanılır.</p>
                        <br/>
                        •	Merkeziyetsiz Borsalarda (DEX) listelenme
                        
                    <p> <br/>Ürün geliştirme aşamalarının ve ön satışın tamamlanmasının ardından, token’i merkeziyetsiz borsalar listemeleri için gerekli teknolojik altyapının sağlandığı aşamadır.</p>
                        <br/>
                        •	Bakım ve teknik destek hizmetleri
                        
                    <p><br/>Token borsalarda yerini aldıktan sonra, OxsBuild akıllı kontrat yapılandırma ve teknik destek konularında hizmetini sürdürür. Bakım ve destek hizmetleri, aylık ücrete tabidir. .</p>
                        <br/>
                        •	MVP, Ürün geliştirme ve karşılama sayfası
                        
                    <p><br/> MVP (Minimum Uygulanabilir Ürün) token’in, geri bildirim almak üzere erken müşterilere sunulan, yeterli özelliklere sahip versiyonudur. MVP ve ürününüz için dizayn edilmiş karşılama web sayfası OxsBuild tarafından tasarlanır. </p>
               

                     <p> <br/> 
                     • Kararlaştırılan kapsamda uçtan uca proje teslimi
                     <br/>
                     <br/>
                     Tüm aşamaların tamamlanmasının ardından, proje kararlaştırıldığı şekliyle teslim edilir.Token geliştirme süreci sona erer. ​</p>
                     </p>
                      `
                },
                {
                    title: "Merkeziyetsiz Borsa (DEX) nedir? DEX listelemesi ne demektir?",
                    text: `<p> Blockchain altyapısıyla dijitalleştirilmiş varlıklar, yatırımcısıyla merkeziyetsiz borsalarda(DEX) buluşur.Temelde DEX’ler, eşler arası herhangi bir takas, merkeziyetsiz bir alım satım yapılmasına imkân veren finansal araçlar barındıran platformlardır.Merkezi olmayan yapısı sayesinde, işlemler anonim, hızlı ve güvenlidir. < br /> <br /></p >
                <p>Tokenomics ve bir token’in başarısını listelenmekte olduğu merkeziyetsiz borsalar ile ilişkilendirmek mümkündür. OxsBuild, tokenizasyon sonrasında, tokenin merkeziyetsiz borsalarda listelenmesi sürecini yönetir.</p>
                    `
                },
                {
                    title: "Tokenomic nedir?",
                    text: `<p>
                Tokenomics; token ve ekonomi kelimelerinin birleşiminden meydana gelmiştir.Tokenlaştırılmış mal ve hizmetlerin değeri, üretimi ve dağıtımına ilişkin tüm ekonomik politikaları tokenomic kapsamına girer.</p >
                <p> <br />Bir tokenin, tokenomic stratejileri projenin büyümesinde kilit rol oynar. Tokenomic, bir varlığın gelecekte ne kadar değerli olabileceğini anlamak için rehber görevi görür. OxsBuild, tokeninizin başarılı bir başlangıç yapabilmesi için, en doğru tokenomic mekanizmasını sizin için belirler. Deneyimli ekibimiz ile tokeninizin arz programını belirlemek ve nokta atışı tokenomic stratejisini oluşturmak için hemen iletişime geçin. </p>`
                },
                {
                    title: "NFT nedir?",
                    text: `<p>
                Nitelikli fikri tapu olarak Türkçeleştirdiğimiz NFT(Non - fungible token), değiştirilemez tokenleri verilen isimdir.Blockchain teknolojisi sayesinde, tokenize edilmiş dijital varlıkların eşsiz ve değiştirilemez oluşu güvence altındadır.Dijital tasarımlar, ses dosyaları, videolar, sosyal medya paylaşımları hatta fiziksel objeler bile dijitalleştirilerek NFT formuna girebilir.
                    </p > <p> <br /> Kendi NFT koleksiyonunuzu tasarlamak ve koleksiyonerlerle buluşturmak için ihtiyacınız olan tüm desteğe OxsBuild uzmanlığı ile ulaşabilirsiniz. Hayalinizdeki NFT Projesinin başlangıcını vermek için hemen bizimle iletişime geçin.
                </p>`
                },

                {
                    title: "Akıllı Kontrat nedir?",
                    text: `<p>
                Akıllı Kontratlar blockchain üzerinde çalışan dijital sözleşmelerdir.Taraflar arasında herhangi bir merkezi otorite olmaksızın, anlaşmaların ve ödemelerin güvenli, düşük maliyetli ve yüksek verimlilik ile gerçekleşmesini sağlar.Sözleşmelerin akla gelebilecek her formu, blockchain teknoloji sayesinde dijital ortama aktarılabilir.Geniş kullanım alanları ve sağladığı avantajlarıyla akıllı kontratlar iş ve sanat dünyasında köklü değişiklikler yapmaya başladı bile.
                    </p > <p> <br />İhtiyacınız doğrultusunda geliştireceğimiz akıllı kontratlar ve blockchain tabanlı merkeziyetsiz uygulamalar sayesinde işinizi hızlı bir büyüme evresine sokabilirsiniz. Blockchain dönüşümünüzü bugün başlatmak için bizimle iletişime geçin.
                </p>`
                },
                {
                    title: "Merkeziyetsiz uygulama (DApp) nedir?",
                    text: `<p>
                Merkeziyetsiz uygulamaların, diğer yazılım uygulamalarından farkı, merkezi bir sunucu yerine blokchain ağı üzerinde çalışıyor olmasıdır.Blockchain tabanlı tüm ürün ve hizmetlerde olduğu gibi veriler anonim, ağ üzerindeki herkes için ulaşılabilir ve şeffaftır.Blockchain üzerinde yedeklenen ve saklanan veriler değiştirilmeye, sansüre ve kötü niyetli girişimlere karşı koruma altındadır.  </p >
                <p> <br /> Merkezi olmayan dünyanın merkeziyetsiz uygulamaları, geleneksel internetin dokunduğu her sektör ve uygulama alanına kolaylıkla entegre edilebilir. Merkeziyetsiz uygulamalar ve iş geliştirme stratejilerinizi projelendirmek için hemen uzmanlarımızla iletişime geçin.
                </p>`
                },
            ]
        },
        contact: {
            title: "Bize ulaşın",
            text: "Oxs Build ile ilgili daha fazla bilgi almak için formu doldurun",
            formText1: "Ad/Soyad",
            formText2: "E-posta",
            formText3: "Telefon",
            formText4: "Mesajınız",
            buttonText: "Gönder"
        },
        socialMediaLinks: {
            twitter: 'https://twitter.com/coinoxsTR',
        },
        clients: {
            title: 'Müşterilerimiz'
        },
        serviceHeros: [
            {
                title: 'Blockchain Dönüşümü',
                text: `Şirketinizin dijital dönüşümünü blockchain teknolojilerinin gücüyle tamamlamak ya da hayalinizdeki blockchain projesini hayata geçirmek istiyorsanız doğru yerdesiniz.`
            },
            {
                title: 'İş Geliştirme Stratejileri',
                text: `OxsBuild, ihtiyaçlarınızı dinler, işiniz için en doğru merkeziyetsiz çözümü belirler ve projelendirir.Oluşturduğu blockchain tabanlı iş geliştirme stratejileri ile şirketinize veya fikrinize değer katar.`
            },
            {
                title: 'Blockchain Tabanlı Hizmetler',
                text: `Tokenizasyon, NFT koleksiyonu oluşturma, akıllı sözleşme yazımı, tokenomic, merkeziyetsiz uygulamalar, blockchain danışmanlığı ve daha fazlası; Coinoxs ekosisteminin blockchain mutfağı OxsBuild’de.`
            }
        ]
    }
}

