import React from 'react'
import Header from './components/Header'
import Hero from './components/Hero'
import Banner from './components/Banner'
import TokenProcess from './components/TokenProcess'
import Footer from './components/Footer'
import ContactForm from './components/Contact'
import SmartContracts from './components/SmartContracts'
import NftProjects from './components/NftProjects'
import FAQ from './components/FAQ'
import ServiceHero from './components/ServiceHero'
import useBreakpoint from 'use-breakpoint'
import Clients from './components/Clients'
import { useContext } from 'react'
import { LanguageContext } from '../../contexts/language.context'
const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }
export default function Home() {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
  const { texts: { serviceHeros } } = useContext(LanguageContext)
  return (
    <div className="home">
      <Header />
      <Hero />
      <div id="about">
        <ServiceHero title={serviceHeros[0].title}
          text={serviceHeros[0].text}
          imageUrl="/assets/images/png/blockchain-transformation.png" />

        <ServiceHero reverse={
          breakpoint === 'desktop'
        }
          title={serviceHeros[1].title}
          text={serviceHeros[1].text}
          imageUrl="/assets/images/png/businness-development.png"
        />
        <ServiceHero
          title={serviceHeros[2].title}
          text={serviceHeros[2].text}
          imageUrl="/assets/images/png/blockchain-services.png"
        />
      </div>
      <div id="tokenization">
        <TokenProcess />
      </div>
      <div id="smart-contracts">
        <SmartContracts />
      </div>
      <div id="nft">
        <NftProjects />
      </div>
      <div id="faq">
        <FAQ />
      </div>
      <div id="clients">
        <Clients />
      </div>
      <ContactForm />
      <Footer />
    </div>
  )
}
