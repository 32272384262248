import classNames from 'classnames';
import React from 'react'
import { useContext } from 'react';
import './style.scss';
import { LanguageContext } from '../../../../contexts/language.context';
export default function SocialMediaIcons({ hiddenSm }) {
    const { texts: { socialMediaLinks } } = useContext(LanguageContext);
    console.log("SocialMediaIcons context", socialMediaLinks);
    const to = (url) => window.open(url, '_blank');
    return (
        <div className={classNames("social-media-icons", {
            "hidden-sm": hiddenSm
        })}>
            <i className="social-media-icons-item social-media-icons-item--discord" onClick={() => to("https://discord.gg/oxstribe")} ></i>
            <i className="social-media-icons-item social-media-icons-item--medium" onClick={() => to("https://coinoxs.medium.com/")} ></i>
            <i className="social-media-icons-item social-media-icons-item--instagram" onClick={() => to("https://www.instagram.com/coinoxs/")} ></i>
            <i className="social-media-icons-item social-media-icons-item--telegram" onClick={() => to("https://t.me/coinoxsturkish")} ></i>
            <i className="social-media-icons-item social-media-icons-item--twitter" onClick={() => to(socialMediaLinks.twitter)} ></i>
        </div>
    )
}
