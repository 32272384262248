import ReCAPTCHA from "react-google-recaptcha";



const ReCaptcha = ({ onChange }) => (<ReCAPTCHA
    sitekey="6LdLXF4hAAAAADPypAXrNyWa_EeIJtnHoplrT4X1"
    onChange={onChange}
/>)

export default ReCaptcha;
