import React from 'react'

export default function Point({title, text}) {
    return (
        <div className="process">
            <div className="dot"></div>
            <h1 className="title"> {title} </h1>
            <p className="text"> {text} </p>
        </div>
    )
}
