import React from 'react'
import { LanguageContext } from '../../../../contexts/language.context';
import './style.scss';


const listItem = (number,text) => <li className="list-item" key={text}>
    <span className='disk'>{number}</span> <span className='text'> {text} </span>
</li>



export default function NftProjects() {
    const {texts} = React.useContext(LanguageContext);
    return (
        <div className='nft-projects'>
            <div className="content">
                <ul className="list">
                    {texts.nftProjects.map((item,index) => listItem(index+1,item))}
                </ul>
            </div>
        </div>
    )
}
